import React, { useEffect, useState } from 'react'
import { IconContext } from 'react-icons/lib'
import {FaBars} from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll'
import { 
    Nav, 
    NavbarContainer, 
    NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks, 
    AppLogo,
    NavLanguageWrap} from './NavbarElements'
import { useTranslation } from 'react-i18next'
import LanguageSelector from '../Footer/LanguageSelector'

const Navbar = ({toggle, survey}) => {
    const {t} = useTranslation()

    const [scrollNav, setScrollNav] = useState(false)
    const headerLogo = require('../../images/header-logo.png')

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    useEffect(()=>{
        window.addEventListener('scroll', changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop()
    }
  return (
    <>
    <IconContext.Provider value={{color: '#191A23'}}>
        <Nav scrollNav = {scrollNav}>
            <NavbarContainer>
                <NavLogo to='/' onClick={toggleHome}><AppLogo src={headerLogo} alt="logo"/></NavLogo>
                <MobileIcon onClick={toggle}>
                    <FaBars/>
                </MobileIcon>
                <NavMenu>
                    <NavItem survey={survey}>
                        <NavLinks 
                            to="features"
                            smooth={true} 
                            duration={500} 
                            spy={true} 
                            exact="true"
                            offset={-80}
                        >
                            {t('nav_bar.nav_bar_link1')}
                        </NavLinks>
                    </NavItem>
                    <NavItem survey={survey}>
                        <NavLinks 
                            to="about"
                            smooth={true} 
                            duration={500} 
                            spy={true} 
                            exact="true"
                            offset={-80}
                        >
                            {t('nav_bar.nav_bar_link2')}
                        </NavLinks>
                    </NavItem>
                    <NavItem survey={survey}>
                        <NavLinks 
                            to="contact"
                            smooth={true} 
                            duration={500} 
                            spy={true} 
                            exact="true"
                            offset={-80}
                        >
                            {t('nav_bar.nav_bar_link3')}
                        </NavLinks>
                    </NavItem>
                    <NavItem survey={survey}>
                        <NavLinks 
                            to="access"
                            main={true}
                            smooth={true} 
                            duration={500} 
                            spy={true} 
                            exact="true"
                            offset={-80}
                        >
                            {t('nav_bar.nav_bar_link4')}
                        </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLanguageWrap main={false}>
                            <LanguageSelector footer={false}/>
                        </NavLanguageWrap>
                    </NavItem>
                </NavMenu>
            </NavbarContainer>
        </Nav>
    </IconContext.Provider>
    </>
  )
}

export default Navbar
