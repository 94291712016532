import styled from "styled-components"

export const FeaturesContainer = styled.div`
    flex-direction: column;
    justify-content: center;
    position: relative;
    background: white;
    margin-top: 30px;
    margin-bottom: 50px;
`

export const FWrapper = styled.div`
    display: grid;
    z-index: 1;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`
export const H1Wrap = styled.div`
    display: flex;
    justify-content: ${({align})=> (align ? 'flex-end': 'flex-start')};
`

export const FeaturesH1 = styled.h1`
    font-size: 2.5rem;
    color: black;
    border-radius: 10px;
    margin-bottom: 32px;
    display: flex;
    font-weight: normal;
    background: #B8AEF2;
    padding: 6px 10px 0px 10px; 

    @media screen and (max-width: 480px){
        font-size: 1.5rem;
        margin-bottom: 10px;
    }
`

export const FeaturesWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0;
        
    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding 0 20px;
    }
`

export const FCard = styled.div`
    background: ${({bgColor}) => bgColor};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    border-radius: 30px;
    height: 100%;
    max-height: 340px;
    padding: 30px 20px 20px 30px;
    box-shadow: 0 5px 2px -2px #191A23;
    transition: all 0.2s ease-in-out;
    border: 1px solid #191A23;

    &:hover{
        transform: scale(1.02);
        transition: all 0.2 ease-in-out;
    }
`

export const FCardContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const FCardH1Wrap = styled.div`
    display: inline;
    text-align: ${({align})=> (align ? 'right': 'left')};
    flex: 1;
`

export const FCardH1 = styled.h1`
    background: ${({titleBg}) => titleBg};
    color: black;
    text-align: left;
    font-weight: normal;
    display: inline;
    box-decoration-break: clone;
    border-radius: 10px;
    font-size: 2.2rem;

    @media screen and (max-width: 1080px){
        font-size: 1.8rem;
    }

    @media screen and (max-width: 935px){
        font-size: 1.5rem;
    }

    @media screen and (max-width: 768px){
        font-size: 1.9rem;
    }

    @media screen and (max-width: 480px){
        font-size: 1.4rem;
    }
`

export const FCardImg = styled.img`
    width: 40%;
    max-width: 250px;
    max-height: 150px;
    margin: 0 0px 10px 5px;
    padding-right: 0;
    flex: 1;
`

export const FCardP = styled.p`
    margin-top: 24px;
    color: ${({textColor}) => textColor};
    font-size: 1.3rem;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width: 768px){
        font-size: 1rem;
    }

    @media screen and (max-width: 480px){
        font-size: 0.7rem;
    }
`