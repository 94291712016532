import React, { useEffect, useState } from 'react'
import { Choices, ChoicesLabel, ContactBtn, ContactInput, ContactTextArea, ContactUsForm } from './ContactElements'
import { useTranslation } from 'react-i18next'
import emailjs from '@emailjs/browser'

const ContactForm = () => {
    const [role, setRole] = useState('Parent')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const { t, i18n } = useTranslation()
    const [alignment, setAlignement] = useState(false)
    const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID
    const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID
    const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY

    useEffect(() => {
        setAlignement(i18n.language === 'ar')
      }, [i18n.language])

    const handleSubmit = (e) => {
        e.preventDefault()

        const templateParams = {
            user_name: name,
            user_role: role,
            user_email: email,
            message: message,
        }

        emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, templateParams, {
                publicKey: EMAILJS_PUBLIC_KEY,
            })
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text)
                alert("Message sent successfully!")
                setName('')
                setEmail('')
                setMessage('')
                setRole('Parent')
            }, (error) => {
                console.log('FAILED...', error)
                alert("Failed to send message. Please try again later.")
            })
        
    }

  return (
    <ContactUsForm onSubmit={handleSubmit} align={alignment}>
        <Choices align={alignment}>
            <ChoicesLabel>
                <input
                    type="radio"
                    value="Parent"
                    checked={role === 'Parent'}
                    onChange={(e) => setRole(e.target.value)}
                />
                {t("contact.form.value1")}
            </ChoicesLabel>
            <ChoicesLabel>
                <input
                    type="radio"
                    value="Clinician"
                    checked={role === 'Clinician'}
                    onChange={(e) => setRole(e.target.value)}
                />
                {t("contact.form.value2")}
            </ChoicesLabel>
        </Choices>
        <label>
            {t("contact.form.head1")}<br />
            <ContactInput align={alignment} type="text" placeholder={t("contact.form.head1")} value={name} onChange={(e) => setName(e.target.value)} />
        </label>
        <label>
            {t("contact.form.head2")}*<br />
            <ContactInput align={alignment} type="email" placeholder={t("contact.form.head2")} value={email} onChange={(e) => setEmail(e.target.value)} required />
        </label>
        <label>
            {t("contact.form.head3")}*<br />
            <ContactTextArea align={alignment} placeholder={t("contact.form.head3")} value={message} onChange={(e) => setMessage(e.target.value)} required />
        </label>
        <ContactBtn 
            type="submit"
            primary = {true}
            big = {false}
            fontBig = {true}
            dark = {false}
        >
            {t("contact.form.link")}
        </ContactBtn>
    </ContactUsForm>
  )
}

export default ContactForm
