import styled from "styled-components"
import { Link } from "react-router-dom"

export const FooterContainer = styled.footer`
    
`

export const FooterWrap = styled.div`
    padding: 24px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    background-color: #191A23;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
`

export const FooterLogo = styled(Link)`
    color: #191A23;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;
    max-width: 250px;
`

export const AppLogo = styled.img`
    width: 100%;
    margin: 10px 0 10px 0;
    padding-right: 0;
`

export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: 100px;
    border-bottom: 1px solid white;

    @media screen and (max-width: 820px){
        padding-top: 32px;
        flex-direction: column;
        align-items: center;
    }
`

export const FooterLinksWrapper = styled.div`
    display: flex;
    width: 100%;

    @media screen and (max-width: 820px){
        flex-direction: column;
    }
`

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px;
    text-align: left;
    box-sizing: border-box;
    color: white;

    @media screen and (max-width: 420px){
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`

export const FooterLinkTitle = styled.h1`
    font-size: 1.5rem;
    margin-bottom: 16px;
    color: #191A23;
    border-radius: 10px;
    background: #B8AEF2;
    padding: 0px 10px 0px 10px; 
`

export const FooterLink = styled.p`
    color: white;
    text-decoration: none;
    margin-bottom: 1rem;
    font-size: 1rem;
`

export const FooterNewsWrapper = styled.div`
    @media screen and (max-width: 820px){
        flex-direction: column;
        align-items: center;
    }
`

export const NewsForm = styled.form`
    padding: 30px;
    background-color: #2A2933;
    border-radius: 20px;
    display: flex; 
    align-items: center; 
    gap: 20px;

    @media screen and (max-width: 820px){
        flex-direction: column;
        align-items: center;
    }
`

export const FooterInput = styled.input`
    width: 100%; 
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    line-height: 3rem;
    padding: 5px 20px;
    background-color: #2A2933;
`

export const FooterBtn = styled.button`
    border-radius: 20px;
    background: ${({primary}) => (primary ? '#191A23' : '#B8AEF2')};
    white-space: nowreap;
    padding: ${({big}) => (big ? '28px 20px' : '12px 30px')};
    color: ${({dark}) => (dark ? 'black' : 'white')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '18px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-iems: center;
    tansition: 0.2s ease-in-out;

    &:hover {
        tansition: 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#B8AEF2' : '#191A23')};
        color: ${({dark}) => (dark ? 'white' : '#191A23')};
    }
`

export const FooterLanguageWrap = styled.div`
    margin-top: 10px;
    text-align: left;
`

export const FooterSelect = styled.select`
    font-size: ${({footer})=> (footer ? '18px': '13px')};  
    text-align:center;    
    background: ${({footer})=> (footer ? '#2A2933': 'none')}; 
    color: ${({footer})=> (footer ? 'white': '#191A23')}; 
    padding: 10px; 
    border-radius: 5px; 
    appearance: none; 

    &:focus {
        outline: none;
    }
`

export const FooterOption = styled.option`
    background-color: gray; 
    color: black; 
    

    &:hover {
        background-color: #B8AEF2; 
    }
`