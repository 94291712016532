import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SurveySection from '../components/Survey'
import Sidebar from '../components/Sidebar'

const Survey = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <Sidebar isOpen = {isOpen} toggle={toggle} survey={true}/>
      <Navbar toggle={toggle} survey={true}/>
      <SurveySection/>
      <Footer />
    </>
  )
}

export default Survey
