import React, { useEffect, useState } from 'react'
import { MCard, MCardContent, MCardH1, MCardH1Wrap, MCardH5, MCardImg, MCardP } from './MissionElements'
import { useTranslation } from 'react-i18next'

const MissionCard = ({bgColor, titleBg, title, sub_title, text, textColor, img}) => {
    const {i18n} = useTranslation()
    const [alignment, setAlignement] = useState(false)
    useEffect(() => {
      setAlignement(i18n.language === 'ar')
    }, [i18n.language])

  return (
    <MCard bgColor={bgColor}>
        <MCardImg src={img} alt='card'/>
        <MCardContent>
            <MCardH1Wrap>
                <MCardH1 titleBg={titleBg}>{title}</MCardH1>
            </MCardH1Wrap>
            <MCardH5 textColor={textColor} align={alignment}>{sub_title}</MCardH5>
            <MCardP textColor={textColor} align={alignment}>{text}</MCardP>
        </MCardContent>
    </MCard>
  )
}

export default MissionCard
