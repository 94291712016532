import React, { useEffect, useState } from 'react'
import { ArrowForward, 
         ArrowRidht, 
         HeroRow,
         Column1,
         Column2, 
         HeroBtnWrapper, 
         HeroContainer, 
         HeroWrapper, 
         HeroH1, 
         HeroP,
         ImgWrap,
         Img,
         HeroBtnContainer} from './HeroElements'
import { Button } from '../ButtonElement'
import { useTranslation } from 'react-i18next'

const HeroSection = () => {
    const {t, i18n} = useTranslation()
    const [hover, setHover] = useState(false)
    const heroImg = require('../../images/hero-img.png')
    const [alignment, setAlignement] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

    useEffect(() => {
      setAlignement(i18n.language === 'ar')
    }, [i18n.language])

  return (
    <HeroContainer>
      <HeroWrapper>
        <HeroRow align={alignment}>
          <Column1 align={alignment}>
            <HeroH1 align={alignment}>{t("home.title")}</HeroH1>
            <HeroP align={alignment}>
              {t("home.subtitle")}
            </HeroP>
            <HeroBtnContainer align={alignment}>
              <HeroBtnWrapper>
                <Button to='contact' 
                        onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        primary = {true}
                        dark = {false}
                        big = {true}>
                    {t("home.home_link")} {hover ? <ArrowForward/> : <ArrowRidht/>}
                </Button>
              </HeroBtnWrapper>
            </HeroBtnContainer>
            
          </Column1>
          <Column2 align={alignment}>
            <ImgWrap>
              <Img src={heroImg} alt="Mystra"/>
            </ImgWrap>
          </Column2>
        </HeroRow>
      </HeroWrapper>
    </HeroContainer>
  )
}

export default HeroSection
