import React, { useState } from 'react'
import { 
    AppLogo,
    FooterBtn,
    FooterContainer, 
    FooterInput, 
    FooterLink, 
    FooterLinkItems, 
    FooterLinksContainer, 
    FooterLinksWrapper, 
    FooterLinkTitle, 
    FooterLogo, 
    FooterNewsWrapper, 
    FooterWrap,
    FooterLanguageWrap, 
    NewsForm} from './FooterElements'
import { animateScroll as scroll } from 'react-scroll'
import LanguageSelector from './LanguageSelector'
import { useTranslation } from 'react-i18next'

const Footer = () => {
    const footerLogo = require('../../images/footer-logo.png')
    const [email, setEmail] = useState('')
    const {t} = useTranslation()

    const toggleHome = () => {
        scroll.scrollToTop()
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const formData = {
            email
        }

        console.log(formData)
    }
  return (
    <FooterContainer id='footer'>
            <FooterWrap>
                <FooterLogo to='/' onClick={toggleHome}><AppLogo src={footerLogo} alt="logo"/></FooterLogo>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>{t("footer.head1")}</FooterLinkTitle>
                            <FooterLink>{t("footer.head2")}mystra.website@gmail.com</FooterLink>
                            <FooterLink>{t("footer.head3")}</FooterLink>
                            <FooterLink>{t("footer.head4")}{t("footer.value4")}</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterNewsWrapper>
                            <NewsForm onSubmit={handleSubmit}>
                                <label>
                                    <FooterInput type="email" placeholder={t("footer.title1")} value={email} onChange={(e) => setEmail(e.target.value)} required />
                                </label>
                                <FooterBtn 
                                    type="submit"
                                    primary = {false}
                                    big = {false}
                                    fontBig = {false}
                                    dark = {true}
                                >
                                    {t("footer.link1")}
                                </FooterBtn>
                            </NewsForm>
                            <FooterLanguageWrap>
                                <LanguageSelector  footer={true}/>
                            </FooterLanguageWrap>
                        </FooterNewsWrapper>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
            </FooterWrap>
      
    </FooterContainer>
  )
}

export default Footer
