import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarLink, SidebarMenu, SidebarLanguageWrap } from './SidebarElements'
import { useTranslation } from 'react-i18next'
import LanguageSelector from '../Footer/LanguageSelector'

const Sidebar = ({isOpen, toggle, survey}) => {
  const {t} = useTranslation()

  return (
    <SidebarContainer isOpen={isOpen}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="features" onClick={toggle} survey={survey}>{t('nav_bar.nav_bar_link1')}</SidebarLink>
                <SidebarLink to="about" onClick={toggle} survey={survey}>{t('nav_bar.nav_bar_link2')}</SidebarLink>
                <SidebarLink to="contact" onClick={toggle} survey={survey}>{t('nav_bar.nav_bar_link3')}</SidebarLink>
                <SidebarLink to="access" onClick={toggle} survey={survey}>{t('nav_bar.nav_bar_link4')}</SidebarLink>
                <SidebarLanguageWrap>
                    <LanguageSelector footer={true}/>
                </SidebarLanguageWrap>
            </SidebarMenu>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
