import styled from "styled-components"

export const AboutContainer = styled.div`
    flex-direction: column;
    justify-content: center;
    position: relative;
    background: white;
    margin-top: 30px;
    margin-bottom: 50px;

`

export const AboutWrapper = styled.div`
    z-index: 1;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 50px;
    justify-content: center;
`
export const H1Wrap = styled.div`
    display: flex;
    justify-content: ${({align})=> (align ? 'flex-end': 'flex-start')};
`

export const AboutH1 = styled.h1`
    font-size: 2.5rem;
    color: black;
    border-radius: 10px;
    margin-bottom: 32px;
    display: flex;
    font-weight: normal;
    background: #B8AEF2;
    padding: 6px 10px 0px 10px; 

    @media screen and (max-width: 480px){
        font-size: 1.5rem;
        margin-bottom: 10px;
    }
`

export const ParentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const AboutContent = styled.div`
    width: 100%;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    box-sizing: border-box;
    background: #191A23;
    border-radius: 20px;
    color: white;
    line-height: 1.5rem;
`

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    border-bottom: none;
    padding-bottom: 0;
  }
`

export const Paragraph = styled.p`
  width: 45%;
  text-align: ${({align})=> (align ? 'right': 'left')};

  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: ${({align})=> (align ? 'right': 'left')};
  }
`

export const VerticalLine = styled.div`
  width: 1px;
  background-color: #ccc;
  height: auto;
  margin: 0 10px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const BottomParagraph = styled.p`
  text-align: left;
  margin-top: 20px;
  width: 40%
`

export const Highlighted = styled.span`
    color: #B8AEF2;
`

export const ParagraphTitle = styled.h1`
    color: #B8AEF2;
    font-size: 2 rem;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 24px;
`