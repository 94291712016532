import React, { useEffect, useState } from 'react'
import { SurveyAnswer, SurveyChecks, SurveyContent, SurveyQuestion, SurveySheetH1 } from './SurveyElements'
import { useTranslation } from 'react-i18next'

const SurveySheet = ({ data, dataTranslated, handleChange }) => {
    const { i18n } = useTranslation()
    const [alignment, setAlignement] = useState(false)

    useEffect(() => {
        setAlignement(i18n.language === 'ar')
      }, [i18n.language])

    return (
        <SurveyContent align= {alignment}>
            <SurveySheetH1>{dataTranslated.sheetTitle}</SurveySheetH1>
            {dataTranslated.questions.map((q, questionIndex) => (
                <div className="form-group" key={q.id}>
                    <SurveyQuestion>{q.question}</SurveyQuestion>
                    <SurveyChecks>
                        {q.answers.map((answer, index) => (
                            <SurveyAnswer className="form-check" key={index} align= {alignment}>
                                <input
                                    className="form-check-input"
                                    type={q.multiChoice ? "checkbox" : "radio"}
                                    name={`question-${q.id}`}
                                    id={`question-${q.id}-answer-${index}`}
                                    value={data.questions[questionIndex].answers[index]}
                                    checked={q.multiChoice ? data.questions[questionIndex].selectedAnswer.includes(data.questions[questionIndex].answers[index]) : data.questions[questionIndex].selectedAnswer[0] === data.questions[questionIndex].answers[index]}
                                    onChange={(e) => handleChange(q.id, e.target.value, q.multiChoice)}
                                    style={{cursor: 'pointer'}}
                                />
                                <label className="form-check-label" htmlFor={`question-${q.id}-answer-${index}`} style={{cursor: 'pointer'}}>
                                    {answer}
                                </label>
                            </SurveyAnswer>
                        ))}
                    </SurveyChecks>
                </div>
            ))}
        </SurveyContent>
    )
}

export default SurveySheet