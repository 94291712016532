import React, { useEffect, useState } from 'react'
import { AboutContainer, AboutContent, AboutH1, AboutWrapper, ParentContainer, H1Wrap, Paragraph, TopSection, VerticalLine, Highlighted, ParagraphTitle } from './AboutElements'
import { useTranslation } from 'react-i18next'

const AboutSection = () => {
    const {t, i18n} = useTranslation()
    const [alignment, setAlignement] = useState(false)

    useEffect(() => {
        setAlignement(i18n.language === 'ar')
      }, [i18n.language])

  return (
    <AboutContainer id="about">
        <AboutWrapper>
            <H1Wrap align={alignment}>
                <AboutH1>{t('about.title')}</AboutH1>
            </H1Wrap>
            <ParentContainer>
                <AboutContent>
                    <TopSection>
                        <Paragraph align={alignment}>
                            <ParagraphTitle>{t('about.left_column.title')}</ParagraphTitle>
                            <Highlighted>{t('about.left_column.highlighted1')}</Highlighted>
                            {t('about.left_column.text1')}
                            <Highlighted>{t('about.left_column.highlighted2')}</Highlighted>
                            {t('about.left_column.text2')}
                            <Highlighted>{t('about.left_column.highlighted3')}</Highlighted>
                            {t('about.left_column.text3')}<br/>
                            {t('about.left_column.text4')}
                        </Paragraph>
                        <VerticalLine />
                        <Paragraph align={alignment}>
                            <ParagraphTitle>{t('about.right_column.title')}</ParagraphTitle>
                            <Highlighted>{t('about.right_column.highlighted1')}</Highlighted>
                            {t('about.right_column.text1')}<br/>
                            {t('about.right_column.text2')}
                        </Paragraph>
                    </TopSection>
                </AboutContent>
            </ParentContainer>
        </AboutWrapper>
      
    </AboutContainer>
  )
}

export default AboutSection
