import styled from "styled-components"

export const MissionContainer = styled.div`
    flex-direction: column;
    justify-content: center;
    position: relative;
    background: white;
    margin-top: 30px;
    margin-bottom: 50px;
`

export const MissionWrapper = styled.div`
    display: grid;
    z-index: 1;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`

export const CardsWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0;
        
    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding 0 20px;
    }
`



export const MCardContent = styled.div`
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 0 24px 0 24px;

    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const MCardH1Wrap = styled.div`
    display: inline;
    text-align: center;
    flex: 1;
`

export const MCardH1 = styled.h1`
    background: ${({titleBg}) => titleBg};
    font-size: 3rem;
    color: black;
    font-weight: bold;
    display: inline;
    box-decoration-break: clone;
    border-radius: 10px;
    padding: 0 10px 0 10px;
`

export const MCardH5 = styled.h5`
    color: ${({textColor}) => textColor};
    font-size: 1.5rem;
    margin-top: 24px;
    font-weight: bold;
    text-align: ${({align})=> (align ? 'right': 'left')};
`

export const MCardImg = styled.img`
    width: 80%;

    flex: 1;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
`

export const MCardP = styled.p`
    margin-top: 24px;
    color: ${({textColor}) => textColor};
    font-size: 1.5rem;
    max-width: 600px;
    text-align: ${({align})=> (align ? 'right': 'left')};

    @media screen and (max-width: 768px){
        font-size: 1.2rem;
    }

    @media screen and (max-width: 480px){
        font-size: 1rem;
    }
`

export const MCard = styled.div`
    background: ${({bgColor}) => bgColor};
    align-items: left;
    border-radius: 30px;
    height: 650px;
    padding: 30px 20px 20px 30px;
    box-shadow: 0 5px 2px -2px #191A23;
    border: 1px solid #191A23;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover ${MCardImg} {
        opacity: 0;
    }

    &:hover ${MCardContent} {
        opacity: 1;
    }
`